import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "author": "nateVick",
  "categories": ["rails", "upgrade"],
  "date": "2019-06-03",
  "path": "/blog/updated-rails-everything-is-on-fire",
  "summary": "Once a Rails app has bundled at the next version of Rails, it is common to have a number of errors to handle before your test suite will run. In this post I walk-through one of the common issues we see.",
  "title": "I Updated Rails. Now Everything's on Fire.",
  "image": "./ogp.png",
  "seo": {
    "og": {
      "type": "article"
    },
    "twitter": {
      "card": "summary_large_image",
      "creator": "@natron99"
    }
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Following up on my `}<a parentName="p" {...{
        "href": "https://hint.io/blog/rails-6-upgrade-best-practices"
      }}>{`best practices post`}</a>{`, I have a prime example of one common issue that surfaces when upgrading a Rails app.`}</p>
    <p>{`I decided to spike on upgrading a small internal app to Rails `}<inlineCode parentName="p">{`6.0.0.rc1`}</inlineCode>{`. The test suite is green on Rails `}<inlineCode parentName="p">{`5.2.3`}</inlineCode>{` and Ruby `}<inlineCode parentName="p">{`2.6.2`}</inlineCode>{`, so I bumped the Rails version in the Gemfile.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`source `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'<https://rubygems.org>'`}</span>{`
git_source`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:github`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`repo`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"<https://github.com/`}<span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`#{`}</span>{`repo`}<span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`}`}</span></span>{`.git>"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

ruby `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'2.6.2'`}</span>{`

gem `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'rails'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'6.0.0.rc1'`}</span></code></pre></div>
    <p>{`In the terminal, I ran `}<inlineCode parentName="p">{`bundle update rails`}</inlineCode>{` and it updated without issue (I mentioned this is a small app, right?). Even on small apps, this should make you raise an eyebrow.`}</p>
    <p>{`Skeptically, I ran the test suite.`}</p>
    <p>{`Errors all the way down.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`An error occurred `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`while`}</span>{` loading ./spec/services/step_processor_spec.rb.
Failure/Error: require File.expand_path`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'../../config/environment'`}</span>{`, __FILE__`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

TypeError:
  Rails::SourceAnnotationExtractor is not a class/module
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# /gems/gems/haml-rails-1.0.0/lib/haml-rails.rb:49:in \`block in <class:Railtie>'`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# ...more output...`}</span>{`

Finished `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.00027`}</span>{` seconds `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`files took `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1.41`}</span>{` seconds to load`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{` examples, `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{` failures, `}<span parentName="code" {...{
            "className": "token number"
          }}>{`17`}</span>{` errors occurred outside of examples`}</code></pre></div>
    <p>{`Based on the error, let's take a look at `}<inlineCode parentName="p">{`haml-rails`}</inlineCode>{` and `}<inlineCode parentName="p">{`SourceAnnotationExtractor`}</inlineCode>{` in Rails to see what changed and if there is a newer version of `}<inlineCode parentName="p">{`haml-rails`}</inlineCode>{`.`}</p>
    <p>{`But wait. You may ask: "Why not try upgrading the gem and move on?". Taking the time to understand the root cause of a problem provides confidence in the solution and clear direction to resolve broader symptoms throughout the codebase.`}</p>
    <div className="callout">
  Taking the time to understand the root cause of a problem provides confidence in the solution and clear direction to resolve broader symptoms throughout the codebase.
    </div>
    <p>{`Here is line 49 in `}<inlineCode parentName="p">{`lib/haml-rails.rb`}</inlineCode>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token constant"
          }}>{`SourceAnnotationExtractor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`Annotation`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`register_extensions`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'haml'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`tag`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span></code></pre></div>
    <p>{`Sure enough, there is a change in Rails 6 that moves `}<inlineCode parentName="p">{`SourceAnnotationExtractor`}</inlineCode>{` to `}<inlineCode parentName="p">{`Rails::SourceAnnotationExtractor`}</inlineCode>{` (`}<a parentName="p" {...{
        "href": "https://github.com/rails/rails/pull/32065"
      }}>{`PR #32065`}</a>{`). The top-level class has been deprecated, but the deprecation warning does not apply to the sub-classes which raise the `}<inlineCode parentName="p">{`TypeError`}</inlineCode>{`.`}</p>
    <p>{`Knowing the source of the error, we can check if there is a newer version of `}<inlineCode parentName="p">{`haml-rails`}</inlineCode>{` compatible with Rails 6. Again, you will find value by not just upgrading the gem, but understanding the changes in the newer versions. With `}<inlineCode parentName="p">{`haml-rails`}</inlineCode>{`, there is a newer version that has a fix for the issue. By bumping up to that version, I'm able to run the test suite without errors and get back to the upgrade at hand.`}</p>
    <p><a parentName="p" {...{
        "href": "https://calendly.com/natevick/upgrade-intro-call"
      }}>{`Schedule a quick call`}</a>{` with me to see how our expertise can keep your team delivering features during your Rails upgrade.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      